<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar">
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex is-justify-content-space-between block">
              <div>
                <h1 class="title">Calculators</h1>
                <h2 class="subtitle">
                  Calculate fees, profits, stop loss, etc.
                </h2>
              </div>
            </div>

            <div class="block">
              <div class="columns is-multiline">
<!--                <div class="column is-one-fifth is-flex"-->
<!--                >-->
<!--                  <div @click="openFTXFeeCalculator" class="card is-clickable"-->
<!--                  >-->
<!--                    <div class="card-content">-->
<!--                      <div class="block">-->
<!--                        <p class="title is-5">FTX</p>-->
<!--                        <p class="subtitle is-6">-->
<!--                          Fee calculator-->
<!--                        </p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="column is-one-fifth is-flex"
                >
                  <div @click="openRRCalculator" class="card is-clickable"
                  >
                    <div class="card-content">
                      <div class="block">
                        <p class="title is-5">PnL & RR</p>
                        <p class="subtitle is-6">
                          Position calculator
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { auth, db } from "@/firebase";

export default {
  name: "AccountCalculator",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
    };
  },
  async created() {
  },
  filters: {
  },
  computed: {
  },
  methods: {
    // openFTXFeeCalculator() {
    //   this.$router.push({ name: 'AccountCalculatorsFTX'})
    // },
    openRRCalculator() {
      this.$router.push({ name: 'AccountCalculatorsRR'})
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  .card-content {
    width: 100%;
    .title {
      flex: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.02, 1.02);

    &::after {
      opacity: 1;
    }
  }
}
</style>
